import { searchPeople } from "./service"

const Model = {
    namespace: 'people',
    state: {
        peopleData: {
            data: [],
            total: 0
        }
    },
    effects: {
        *searchPeople({ payload }: any, { call, put }: any) {
            const response: Promise<any> = yield call(searchPeople, payload)

            yield put({
                type: 'setPeople',
                payload: response
            })
        }
    },
    reducers: {
        setPeople(state: any, { payload }: any) {
            return {
                ...state, 
                peopleData: payload
            }
        }
    }
}


export default Model