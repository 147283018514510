import React from 'react';
import { Layout } from 'antd';

const { Footer: AntFooter } = Layout;

const Footer: React.FC = () => {
  return (
    <AntFooter style={{ background: 'none', textAlign: 'center', paddingTop: 24, paddingBottom: 0 }}>
      <a
        href="https://tryscout.com"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: 'inherit', textDecoration: 'none' }}
      >
        SCOUT All rights reserved © 2024
      </a>
    </AntFooter>
  );
};

export default Footer;
