// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/user","layout":false,"id":"1"},"2":{"name":"lgin In","path":"/user/login","parentId":"1","id":"2"},"3":{"path":"/search","name":"Search","icon":"SearchOutlined","parentId":"ant-design-pro-layout","id":"3"},"4":{"path":"/lists","name":"Lists","icon":"UnorderedListOutlined","parentId":"ant-design-pro-layout","id":"4"},"5":{"path":"/","redirect":"/user/login","parentId":"ant-design-pro-layout","id":"5"},"6":{"path":"*","layout":false,"id":"6"},"ant-design-pro-layout":{"id":"ant-design-pro-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import('./EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__User__Login__index" */'@/pages/User/Login/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__Search__index" */'@/pages/Search/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__List__index" */'@/pages/List/index.tsx')),
'5': React.lazy(() => import('./EmptyRoute')),
'6': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.tsx')),
'ant-design-pro-layout': React.lazy(() => import(/* webpackChunkName: "t__plugin-layout__Layout" */'/Users/gurkaransingh/Github/scout-frontend/src/.umi-production/plugin-layout/Layout.tsx')),
},
  };
}
