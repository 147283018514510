import { request } from "@umijs/max";
import Cookies from "js-cookie";

export async function login(body: API.LoginParams) {
    try {
        console.log(body);
        const response = await request<any>('/user/login', {
            method: 'POST',
            data: body,
        });
        console.log('response', response);

        return response;
    } catch (error) {
        console.error('Error during login request:', error);
        throw new Error('Login request failed');
    }
}


export async function currentUser() {
    return request<{ data: API.CurrentUser }>('/user/api/currentUser', {
        method: 'GET'
    });
}
