import { SideBarBottom, AvatarName, Footer } from '@/components';
import type { Settings as LayoutSettings } from '@ant-design/pro-components';
import type { RunTimeLayoutConfig } from '@umijs/max';
import { history } from '@umijs/max';
import defaultSettings from '../config/defaultSettings';
import { errorConfig } from './requestErrorConfig';
import React, { useEffect, useState } from 'react';
import { Col, Space, Typography } from 'antd';
import { useMediaQuery } from '@mui/material';
import { currentUser } from './pages/User/Login/service';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Sentry } from 'umi';
import { SubscriptionCard } from './components/RightContent/subscription_card';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAiG9qdW_tnXjyyqzobxEcaeBmD64BZJQg",
  authDomain: "scout-e78e3.firebaseapp.com",
  projectId: "scout-e78e3",
  storageBucket: "scout-e78e3.appspot.com",
  messagingSenderId: "506508788463",
  appId: "1:506508788463:web:fd5e170891958dcf27d11a",
  measurementId: "G-9YLCJ9X56N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const loginPath = '/user/login';

export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: any;
  loading?: boolean;
  fetchUserInfo?: () => Promise<any>;
}> {
  const strin = Sentry.captureException(new Error('my error'));
  console.log(strin);

  const fetchUserInfo = async () => {

    try {
      const msg = await currentUser();

      return msg;
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };

  const { location } = history;
  if (location.pathname !== loginPath) {
    const currentUser = await fetchUserInfo();
    return {
      fetchUserInfo,
      currentUser,
      settings: defaultSettings as Partial<LayoutSettings>,
    };
  }
  return {
    fetchUserInfo,
    settings: defaultSettings as Partial<LayoutSettings>,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }: any) => {

  // const [collapse, setCollapse] = useState(false); // state management of logo
  const [collapsed, setCollapsed] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 1402px)');

  useEffect(() => {
    setCollapsed(isSmallScreen);
  }, [isSmallScreen]);


  return {
    actionsRender: () => [],

    onCollapse: (collapsed: boolean) => {
      setCollapsed(collapsed);
    },
    siderWidth: 220,
    title: false,
    collapsed: collapsed,
    className: 'layout-root',
    logo: <Space align='start'>
      {!collapsed ? <img src="/scout_logo.png" width={134} alt="logo" /> : <img src="/scout_logo_head.png" width={40} alt="logo" />}
    </Space>,
    logoStyle: {
      backgroundColor: 'yellow',
      display: 'flex',
      justifyContent: 'center',
    },
    breakpoint: 'xl',           // for sider bar
    avatarProps: {
      src: initialState?.currentUser?.avatar,
      title: <AvatarName />,
      render: (_, avatarChildren) => {
        return <SideBarBottom>{avatarChildren}</SideBarBottom>;
      },
    },
    menuContentRender: (props, defaultDom) => {
      return <>
        {defaultDom}

        {!collapsed && <SubscriptionCard />}
      </>
    },
    footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      if (!initialState?.currentUser && location.pathname !== loginPath) {
        history.push(loginPath);
      }
    },
    bgLayoutImgList: [
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/F6vSTbj8KpYAAAAAAAAAAAAAFl94AQBr',
        bottom: 0,
        left: 0,
        width: '331px',
      },
    ],

    childrenRender: (children: any) => {
      return (
        <>
          {children}
        </>
      );
    },
    ...initialState?.settings,
  };
};

export const request = {
  ...errorConfig,
};