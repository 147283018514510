export default {
  'component.globalHeader.search': 'Site Search',
  'component.globalHeader.search.example1': 'Search Suggestion One',
  'component.globalHeader.search.example2': 'Search Suggestion Two',
  'component.globalHeader.search.example3': 'Search Suggestion Three',
  'component.globalHeader.help': 'Help Document',
  'component.globalHeader.notification': 'Notification',
  'component.globalHeader.notification.empty': 'You have viewed all notifications',
  'component.globalHeader.message': 'Message',
  'component.globalHeader.message.empty': 'You have read all messages',
  'component.globalHeader.event': 'To-Do',
  'component.globalHeader.event.empty': 'You have completed all to-dos',
  'component.noticeIcon.clear': 'Clear',
  'component.noticeIcon.cleared': 'Cleared',
  'component.noticeIcon.empty': 'No Data',
  'component.noticeIcon.view-more': 'View More',
};
