import { fetchProductListItems } from "./service";

const Model = {
    namespace: 'list',
    state: {
        productLists: {}
    },
    effects: {
        *fetchProductListItems({ payload, callback }: any, { call, put }: any) {
            let response: Promise<any> = yield call(fetchProductListItems, payload.id);
            response = (response as any).map((item: any) => {
                return {
                    item_id: item.id,
                    product_id: item.product.id,
                    name: item.product.name,
                    packaging_type: item.product.packaging_type,
                    manufacturer_details: item.product.manufacturer_details,
                    country_of_origin: item.product.country_of_origin,
                }
            })
            yield put({
                type: 'setProductListItems',
                payload: {
                    id: payload.id,
                    items: response
                }
            });

            if (callback) {
                callback(response);
            }
        }
    },
    reducers: {
        setProductListItems(state: any, { payload }: any) {
            return {
                ...state,
                productLists: {
                    ...state.productLists,
                    [payload.id]: payload.items
                }
            };
        }
    }
}

export default Model;