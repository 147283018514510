import React, { useEffect, useRef, useState } from 'react';
import { Button, Typography } from 'antd';
import { Gradient } from './gradient.js';

const SubscriptionCard = () => {
    // Create your instance
    const gradient = new Gradient();
    const overlayRef = useRef<any>(null);
    const [canvasHeight, setCanvasHeight] = useState(300); // Initial height guess

    useEffect(() => {
            gradient.initGradient('#gradient-canvas');
            const resizeCanvas = () => {
                if (overlayRef.current) {
                    setCanvasHeight(overlayRef.current.offsetHeight);
                }
            };

            resizeCanvas();
            window.addEventListener('resize', resizeCanvas);

            return () => {
                window.removeEventListener('resize', resizeCanvas);
            };
    });

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: `266px`, // Use state to control height dynamically
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        borderRadius: '8px',
        color: 'white',
        padding: '20px',
        boxSizing: 'border-box',
        textAlign: 'center',
    };

    const canvasStyle = {
        width: '100%',
        height: `266px`, // Use state to control height dynamically
        borderRadius: '8px',
        overflow: 'hidden'
    };

    return (
        <div style={{ position: 'relative', marginTop: '12px' }}>
            <canvas id="gradient-canvas" style={canvasStyle} data-transition-in />
            <div ref={overlayRef} style={overlayStyle}>
                <Typography.Title level={4} style={{ fontWeight: 'bolder', color: 'white', marginBottom: '12px' }}>Need More Data? Upgrade Now!</Typography.Title>
                <Typography.Paragraph style={{ color: 'white' }}>
                    Ensure uninterrupted access to potential customers top up your credits today.
                </Typography.Paragraph>
                <Button size="large" style={{ marginTop: '16px', backgroundColor: 'white', color: 'black' }}>Checkout Pricing</Button>
            </div>
        </div>
    );
}

export { SubscriptionCard }
