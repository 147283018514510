import { request } from "@umijs/max";

export async function fetchProductsByIngredient(params: any) {
  return request('/data/fetch_products_by_ingredient', {
    method: 'POST',
    data: params,
  });
}

export async function fetchProduct(productId: any) {
  return request(`/data/fetch_product/${productId}`);
}

export async function autoCompletePackagingType(params: any) {
  return request(`/data/autocomplete_packaging_type/${params}`);
}

export async function fetchProductLists() {
  return request('/data/fetch_product_lists');
}

export async function createProductList(params: any) {
  try {
    return await request('/data/create_product_list', {
      method: 'POST',
      data: params,
    });
  } catch (error) {
    throw error;
  }
}

export async function addProductsToList(params: any) {
  return request('/data/add_products_to_list', {
    method: 'POST',
    data: params,
  });
}